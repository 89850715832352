.cookies {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 1001;
  padding-top: 1rem;
  padding-bottom: 1rem;
  p {
    font-size: 1rem;
    margin-bottom: 0;
    color: #fff;
    font-weight: 400;
  }
  a {
    text-decoration: underline;
    white-space: nowrap;
    color: #fff;
    &:hover {
      text-decoration: none;
    }
  }

  .btn,
  .btn:hover,
  .btn:focus {
    margin: 1rem auto 0;
    text-decoration: none;
  }
}
