.login {
    background: url("img/login-bg-xl-2.jpg") no-repeat center;
    background-size: cover;
    position: relative;
    //@include media-breakpoint-up(sm) {
    //    background-image: url("img/login-bg-md.jpg");
    //}
    //@include media-breakpoint-up(md) {
    //    background-image: url("img/login-bg-lg-2.jpg");
    //}
    //@include media-breakpoint-up(lg) {
    //    background-image: url("img/login-bg-xl-2.jpg");
    //}
    // &::before {
    //     content: "";
    //     display: block;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     background-color: rgba(0, 0, 0, 0.5);
    // }
    input {
        border: none;
    }
}
.login-container {
    background-color: rgba(0, 0, 0, 0.50);
}

.footer {
    @extend .bg-dark;

    li > a {
        @extend .text-white;
    }
}

.login-footer {
    @extend .bg-light;
    background: rgba(255, 255, 255, 0.5) !important;

    li > a {
        @extend .text-dark;
    }
}

