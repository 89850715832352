ol,
ul,
dl {
  margin-bottom: $paragraph-margin-bottom;
}
table {
  font-size: 0.875rem;
}
tbody th {
  font-weight: normal;
}
h1 strong {
  font-weight: 500;
}
