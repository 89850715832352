.card {
  background-image: linear-gradient(to bottom, #fefefe, #f2f2f2);

  &--socis {
    .display-4 {
      color: #2db1af;
    }
  }
  &--amics {
    .display-4 {
      color: #47c27c;
    }
  }
}
