.duplicats-modal {
  position: fixed;
  bottom: -100%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 90%;
  z-index: 2000;
  max-height: 45%;
  overflow: hidden;

  background: #fff;

  border-radius: 2rem 2rem 0 0;
  padding: 2rem;
  box-shadow: 0 -2px 10px 5px rgba(0, 0, 0, 0.4);

  transition: bottom 500ms;

  display: flex;
  flex-direction: column;

  &.show {
    bottom: 0;
  }

  &-content {
    overflow-x: hidden;
    overflow-y: auto;
  }

  dl {
    margin: 1rem;
  }

  dd {
    margin: 0;
  }
}
