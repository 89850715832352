.filters-list {
  .badge {
    background-color: #dfdfdf;
    border: solid 1px #979797;
    background-image: linear-gradient(to bottom, #f7f7f7 2%, #dfdfdf 95%);
    padding-right: 0;
  }
  .close {
    font-size: 1.125rem;
    width: 2rem;
    top: -2px;
    position: relative;
  }
}
