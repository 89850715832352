.btn-dark {
  background-color: #000;
  border-color: #000;
}
.btn-toggle {
  &[aria-expanded="true"] {
    > *:last-child {
      transform: rotate(0.5turn);
      margin-top: -3px;
    }
  }
}
.btn-back {
  font-size: 0.875rem;
  background-color: $gray-400;
  padding-left: 1rem;
  padding-right: 1rem;
}
