label {
  font-size: $font-size-sm;
}
.form-control,
.custom-control-label::before {
  background-image: linear-gradient(to bottom, #f7f7f7, $white);
}
.form-control[readonly]::placeholder {
  color: $black;
}

.invalid-feedback,
.valid-feedback {
  font-weight: bold;
}
.vip-check {
  span {
    top: 0.125rem;
  }
}
.form-control.icon {
  padding-right: 3rem;
  background-repeat: no-repeat;
  background-position: calc(100% - 0.5rem);
  background-color: $white;
  &-caducat {
    background-image: url("img/ico-nif-imatge-caducada.svg");
  }
  &-disp {
    background-image: url("img/ico-nif-imatge-disponible.svg");
  }
  &-nodisp {
    background-image: url("img/ico-nif-imatge-no-disponible.svg");
  }
}
