.perfil-amic {
    color: $amic;
}
.perfil-soci {
    color: $soci;
}
.perfil-possible {
    color: $possible;
}
.estat-actiu {
    color: $actiu;
}
.estat-cancelat {
    color: $cancelat;
}
.estat-suspes {
    color: $suspes;
}

.bg-orange {
    background: #FF7F50 !important;
}
