.fitxa-persona {
  input[readonly],
  textarea[readonly],
  input[disabled],
  textarea[disabled],
  select[disabled],
  select[readonly] {
    background: rgb(215, 215, 215);
    background: linear-gradient(180deg, rgba(231, 231, 231, 1) 0%, rgba(239, 239, 239, 1) 100%) !important;

    &.bg-success {
      background-image: none !important;
      @extend .bg-success;
    }

    &.bg-danger {
      background-image: none !important;
      @extend .bg-danger;
    }

    &.bg-warning {
      background-image: none !important;
      @extend .bg-warning;
    }
  }

  select.form-control {
    height: 0.0625rem * 2 + $input-padding-y * 2 + $input-font-size * $input-line-height;
  }
}
