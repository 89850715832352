.table-sm {
    font-size: 0.75rem;
    font-weight: 300;
    span {
        font-size: 0.875rem;
    }
    thead {
        th {
            font-weight: 500;
            border-top: 0;
            padding-top: 0;
        }
    }
    tbody {
        strong,
        span {
            font-weight: 500;
            vertical-align: middle;
        }
    }
}

// Mida màxima taules
div.table-responsive-y {
    max-height: 30rem;
    overflow-y: auto;
}