.chosen-container-single .chosen-single abbr,
.chosen-container-single .chosen-single div b,
.chosen-container-single .chosen-search input[type="text"],
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close,
.chosen-rtl .chosen-search input[type="text"] {
  background-image: url("img/chosen-sprite.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 144dpi),
  only screen and (min-resolution: 1.5dppx) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type="text"],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: url("img/chosen-sprite@2x.png") !important;
  }
}

.chosen-container {
  .chosen-results {
    font-size: 0.875rem;
    color: $body-color;
    padding-bottom: 0.5rem;
    li {
      padding: 0.5rem 1rem;
      &.highlighted {
        color: inherit;
        background-color: $gray-300;
        background-image: none;
      }
    }
  }
  .chosen-drop {
    box-shadow: none;
    border-color: $gray-500;
    .result-selected {
      color: $gray-500;
    }
  }
}
.chosen-container-multi .chosen-choices {
  display: block;
  padding: $input-padding-y 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid #979797;
  border-radius: $border-radius;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  background-image: linear-gradient(to bottom, #f7f7f7, $white);
}
.chosen-container-active .chosen-choices {
  color: #000;
  background-color: #fff;
  border-color: #4fd6ff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 158, 206, 0.25);
}
