.lang {
  font-size: 0.875rem;
  @include media-breakpoint-up(md) {
    margin-top: 1px;
  }
  a {
    text-decoration: underline;
  }
  > * {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}
