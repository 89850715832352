.alert-floating {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    box-shadow: 0px 20px 0px -10px rgba(0, 0, 0, 0.25);

    .close {
        padding: 1rem;
        color: $black;
    }
}

.alert-floating.alert-warning {
    padding-right: $alert-padding-x;
}

.alert-info-natural {
    background-color: #aed6f1;
}
