.tabs-container {
    background-image: linear-gradient(to bottom, #ffffff, #eaeaea 98%);
}
.nav-tabs {
    font-size: 0.75rem;
    font-weight: 500;
}
.nav-item {
    border-right: 1px solid #ced4da;
    @include media-breakpoint-up(xl) {
        flex-basis: 10ch;
    }
}
.nav-link {
    color: #000;
    border: 1px solid #979797;
}

// mostrar contingut dels tabs si no hi ha js
.no-js {
    .tab-content {
        > .tab-pane {
            display: block;
        }
    }
    .fade:not(.show) {
        opacity: 1;
    }
}
