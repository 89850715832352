.dataTables_info {
  font-size: 0.875rem;
}
.dataTables_wrapper .dataTables_paginate {
  .pagination {
    font-size: 0.875rem;
  }
  .paginate_button {
    padding: 0;
    border: 0;
    &:hover {
      background: none;
      border: 0;
    }
  }
  .page-item.active {
    .page-link {
      background-color: #97e9f3;
      border-color: #97e9f3;
      box-shadow: none;
    }
  }
  .page-link {
    border-radius: 50%;
    color: $black;
    &:hover {
      background: $gray-300;
      border: 0;
    }
  }
  .previous,
  .next {
    font-weight: bold;
    .page-link {
      border-radius: 0;
      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  }
}
table.dataTable thead th,
table.dataTable.no-footer {
  border-bottom: 0;
}

// icones mostrar/ocultar info en table responsive
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control::before {
  top: 0.75rem;
  left: 0.5rem;
  // height: 1em;
  // width: 1em;
  margin-top: 0;
  // display: block;
  // position: absolute;
  // color: #fff;
  border: none;
  // border-radius: 1em;
  box-shadow: none;
  // box-sizing: content-box;
  // text-align: center;
  // text-indent: 0 !important;
  // font-family: "Courier New",Courier,monospace;
  // line-height: 1em;
  // content: "+";
  // background-color: #31b131;
}

.results-table {
  td,
  th {
    border: 0;
  }
  th a {
    text-decoration: underline;
  }
}
