.domiciliacio-anulada {
  background-color: rgb(214, 219, 223);
}

.domiciliacio-congelada {
  background-color: rgb(201, 167, 255);
}

.domiciliacio-puntual {
  background-color: rgb(255, 202, 102);
}

.domiciliacio-a-futur {
  background-color: rgb(174, 214, 241);
}

.domiciliacio-activa {
  background-color: rgb(170, 242, 126);
}

.domiciliacio-activa-amb-baixa {
  background-color: rgb(240, 230, 140);
}

.domiciliacio-baixa {
  background-color: rgb(255, 129, 129);
}
